#map {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
}

.leaflet-container {
    background: #1a1a1a;
}

.dark-tiles {
    filter: grayscale(100%) invert(92%) hue-rotate(180deg) brightness(85%);
}

.leaflet-control-attribution {
    background: rgba(0, 0, 0, 0.7) !important;
    color: #ffffff !important;
}