.layout {
    position: relative;
    height: 100vh;
    background-color: #1a1a1a;
    color: #ffffff;
}

.layout-header {
    position: absolute;
    top: 20px;
    left: 100px;
    z-index: 1000;
    background: transparent;
}

.layout-header h1 {
    font-size: 1.5rem;
    font-weight: 400;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin: 0;
}

.layout-main {
    height: 100%;
    width: 100%;
}